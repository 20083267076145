
import { api, TSettingKey } from "@/api";
import { Options, Vue } from "vue-class-component";

@Options({})
export default class Email extends Vue {
    username = '';
    password = '';
    server = '';
    admin = '';
    testReceiver = '';

    async mounted() {
        await this.fetchData();
    }

    async fetchData() {
        this.username = (await api.admin.getSetting(TSettingKey.SkMailUsername)).Value;
        this.password = (await api.admin.getSetting(TSettingKey.SkMailPassword)).Value;
        this.server = (await api.admin.getSetting(TSettingKey.SkMailServer)).Value;
        this.admin = (await api.admin.getSetting(TSettingKey.SkMailAdmin)).Value;
    }

    async onSave() {
        const loading = this.$loading();
        try {
            await api.admin.setSetting(TSettingKey.SkMailUsername, this.username);
            await api.admin.setSetting(TSettingKey.SkMailPassword, this.password);
            await api.admin.setSetting(TSettingKey.SkMailServer, this.server);
            await api.admin.setSetting(TSettingKey.SkMailAdmin, this.admin);
            this.$message("保存成功");
        } finally {
            loading.close();
        }
    }

    async onSend() {
        const loading = this.$loading();
        try {
            await api.admin.sendTestMail(this.testReceiver);
            this.$message("发送成功");
        } finally {
            loading.close();
        }
    }
}
