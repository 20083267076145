import ElementPlus from 'element-plus';
import * as ElIconModules from '@element-plus/icons-vue';
import 'element-plus/theme-chalk/index.css';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { createApp } from 'vue'
import App from './App.vue';
import router from './router';
import options, { setAppContent } from './quill-options';

// Vue and router
const app = createApp(App);
app.use(router);

// ElementUI and icons
app.use(ElementPlus);
for (let iconName in ElIconModules) {
    app.component('i' + iconName.replace(/[A-Z]/g, (match) => '-' + match.toLowerCase()), (ElIconModules as any)[iconName])
}

// Quill editor
setAppContent(app._context);
QuillEditor.props.globalOptions.default = () => options;
app.component('vue-quill', QuillEditor);

app.mount('#app');

