
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class Header extends Vue {
  get defaultActiveMenu() {
    const path = this.$route.path;
    if (
      path.startsWith("/sharing/")) {
      return "/sharing";
    }
    return path;
  }
}
