import { api } from '@/api';
import FileExplorer from './FileExplorer.vue';

const SharingExplorer: any = { ...FileExplorer };
SharingExplorer.methods = { ...(FileExplorer as any).methods };
SharingExplorer.props = { ...(FileExplorer as any).props };

SharingExplorer.emits = (FileExplorer as any).emits ? [...(FileExplorer as any).emits] : [];

SharingExplorer.emits.push('update:path');
SharingExplorer.props.path = { type: String, required: false, default: '' };
SharingExplorer.setup = function (props: any, context: any) {
    const ret = (FileExplorer as any).setup(props, context);
    ret.profileKey = 'SharingExplorer.profile';
    ret.toolbar = false;
    ret.selectedItemPath = props.path;
    return ret;
}

SharingExplorer.methods.fetchData = async function () {
    this.$emit('update:path', this.profile.path);
    const items = await api.public.listFiles(this.profile.path);
    this.items = items.map(x => ({ ...x, FullPath: 'sharing/' + this.profile.path + x.Name }));
};

SharingExplorer.methods.isImage = () => false;

export default SharingExplorer;