
import { api, TComment, TPost, TSettingKey } from '@/api';
import { lchown } from 'fs';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class Post extends Vue {
    post: TPost | null = null;
    comments: TComment[] | null = null;
    async mounted() {
        this.readProfile();
        this.post = await api.public.getPost(this.$route.params.path as string);
        try {
            this.isAdmin = (await api.admin.hello()) === "hello world!";
            if (this.isAdmin) {
                this.editing.Author = 'march1993';
                this.editing.Email = (await api.admin.getSetting(TSettingKey.SkMailAdmin)).Value;
            }
        } catch (e) { }
        await this.fetchComment();
    }

    async fetchComment() {
        this.comments = await (this.isAdmin ? api.admin : api.public).getComments(this.post!.ID);
    }

    isAdmin = false;

    editing: TComment = {
        ID: 0,
        PostID: 0,
        Date: 0,
        IP: '',
        Author: '',
        Email: '',
        Content: '',
    };

    avatar(item: TComment): string {
        return 'https://www.gravatar.com/avatar/' + api.md5(item.Email.trim().toLowerCase());
    }

    readProfile() {
        this.editing.Author = window.localStorage.getItem('Commenter.Author') || '';
        this.editing.Email = window.localStorage.getItem('Commenter.Email') || '';
    }
    writeProfile() {
        window.localStorage.setItem('Commenter.Author', this.editing.Author);
        window.localStorage.setItem('Commenter.Email', this.editing.Email);
    }

    async onSave() {
        this.writeProfile();

        const l = this.$loading();
        try {
            await api.public.setComment({
                ID: 0,
                Date: 0,
                PostID: this.post!.ID,
                IP: '',
                Author: this.editing.Author,
                Email: this.editing.Email,
                Content: this.editing.Content,
            });
            this.editing.Content = '';
            await this.fetchComment();
        } finally {
            l.close();
        }
    }

    async onDelete(item: TComment) {
        const l = this.$loading({});
        try {
            await this.$confirm("是否删除该评论？");
            await api.admin.delComment(item);
            await this.fetchComment();
            this.$message("删除成功");
        } catch (e) {
        } finally {
            l.close();
        }

    }
}
