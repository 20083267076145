
import { Options, Vue } from "vue-class-component";
import { api } from "./api";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

@Options({
  components: {
    "the-header": Header,
    "the-footer": Footer,
  },
})
export default class App extends Vue {
  mounted() {
    this.$route;
    if (window.location.hostname === "localhost") {
      (window as any).api = api;
    }
  }

  // @Watch("$route", { immediate: false, deep: false })
  // onUrlChange(newVal: Route, oldVal: Route) {
  //   window.scrollTo(0, 0);
  // }
}
