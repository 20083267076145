
import { api, Locales, TPost } from "@/api";
import { Options, Vue } from "vue-class-component";

@Options({})
export default class Posts extends Vue {
    rawPosts: Omit<TPost & { NComment: number }, 'Content'>[] = [];
    locales = Locales;

    get posts(): typeof this.rawPosts {
        const copied = this.rawPosts.slice(0);
        copied.sort((a, b) => b.Date - a.Date);
        return copied;
    }

    async mounted() {
        await this.fetchData();
    }

    async fetchData() {
        this.rawPosts = await api.admin.getPosts();
    }

    async onDelete(post: TPost) {
        const l = this.$loading({});
        try {
            await this.$confirm("是否删除该文章？");
            await api.admin.delPost(post);
            await this.fetchData();
            this.$message("删除成功");
        } catch (e) {
        } finally {
            l.close();
        }
    }

    async onEdit(post: TPost) {
        const loading = this.$loading();
        this.editing = await api.admin.getPost(post.ID);
        loading.close();
        this.showEditing = true;
    }

    onView(post: TPost) {
        window.open('/post/' + post.Path, '_blank');
    }

    onCreate() {
        if (this.editing === null || this.editing.ID !== 0) {
            this.editing = this.newPost();
        }
        this.showEditing = true;
    }

    newPost(): TPost {
        return {
            ID: 0,
            Locale: Locales[0],
            Path: '',
            Date: 0,
            Title: '未命名文章',
            Content: '<p>正文</p>',
        } as const;
    }

    async onSave() {
        const l = this.$loading();
        try {
            const id = await api.admin.setPost(this.editing!);
            if (0 === this.editing?.ID) {
                this.editing.ID = id;
            }
            this.showEditing = false;
            await this.fetchData();
            this.$message("保存成功");
        } catch (e) {
            this.$message.error("保存失败");
        } finally {
            l.close();
        }
    }

    showEditing = false;
    editing: TPost | null = null;

    async onCloseEditing(done: () => void) {
        await this.$messageBox.confirm('确认关闭？');

        done();
    }
}
