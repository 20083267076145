
import { api, TPost } from '@/api';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class About extends Vue {
  about: string | null = null;
  async mounted() {
    this.about = await api.public.getAbout();
  }
}
