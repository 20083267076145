<template>
  <div class="entity">
    <h1>
      <i>Welcome</i>
    </h1>
  </div>
</template>
<style lang="less" scoped>
.entity {
  text-align: right;
}
</style>