import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import AdminLogin from '@/views/admin/Login.vue';
import AdminPanel from '@/views/admin/Panel.vue';
import AdminWelcome from '@/views/admin/Welcome.vue';
import AdminSetPassword from '@/views/admin/SetPassword.vue';
import AdminPosts from '@/views/admin/Posts.vue';
import AdminAbout from '@/views/admin/About.vue';
import AdminFiles from '@/views/admin/Files.vue';
import AdminEmail from '@/views/admin/Email.vue';
import AdminSite from '@/views/admin/Site.vue';

import PageNotFound from "@/views/PageNotFound.vue";
import Post from '../views/Post.vue';
import About from '../views/About.vue';
import Posts from '../views/Posts.vue';
import Sharing from '../views/Sharing.vue';

const routes: Array<RouteRecordRaw> = [
  { path: '/', name: 'Home', redirect: '/posts' },
  { path: '/posts', name: 'Posts', component: Posts },
  { path: '/about', name: 'About', component: About },
  { path: '/post/:path', name: 'Post', component: Post },
  // { path: '/sharing', redirect: '/sharing/' },
  { path: '/sharing/:path(.*)?', name: 'Sharing', component: Sharing },


  { path: '/admin/login', name: 'AdminLogin', component: AdminLogin },
  {
    path: '/admin/panel', component: AdminPanel, children: [
      { path: '', name: 'AdminWelcome', component: AdminWelcome },
      { path: 'set-password', name: 'AdminSetPassword', component: AdminSetPassword },
      { path: 'about', name: 'AdminAbout', component: AdminAbout },
      { path: 'files', name: 'AdminFiles', component: AdminFiles },
      { path: 'posts', name: 'AdminPosts', component: AdminPosts },
      { path: 'email', name: 'AdminEmail', component: AdminEmail },
      { path: 'site', name: 'AdminSite', component: AdminSite },
    ],
  },


  // { path: '/404', name: "PageNotFound", component: import("@/views/PageNotFound.vue") },
  // { path: "/:catchAll(.*)", redirect: '/404' },
  { path: "/:catchAll(.*)", name: "PageNotFound", component: PageNotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
