import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-200887b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "entity" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_file_explorer = _resolveComponent("file-explorer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_file_explorer, {
      class: "the-explorer",
      multiple: true
    })
  ]))
}