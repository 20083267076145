
import { api, TSettingKey } from "@/api";
import { Options, Vue } from "vue-class-component";

@Options({})
export default class Site extends Vue {
    sitePrefix = '';
    siteLinks = '';

    async mounted() {
        await this.fetchData();
    }

    async fetchData() {
        this.sitePrefix = (await api.admin.getSetting(TSettingKey.SkSitePrefix)).Value;
        this.siteLinks = (await api.admin.getSetting(TSettingKey.SkSiteLinks)).Value;
    }

    async onSave() {
        this.siteLinks = this.siteLinks.trim();
        while (this.sitePrefix.endsWith('/')) {
            this.sitePrefix = this.sitePrefix.slice(0, this.sitePrefix.length - 1);
        }

        const loading = this.$loading();
        try {
            await api.admin.setSetting(TSettingKey.SkSitePrefix, this.sitePrefix);
            await api.admin.setSetting(TSettingKey.SkSiteLinks, this.siteLinks);
            this.$message("保存成功");
        } finally {
            loading.close();
        }
    }
}
