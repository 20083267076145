import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c20f06a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "entity" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sharing_explorer = _resolveComponent("sharing-explorer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_sharing_explorer, {
      "onUpdate:path": _ctx.onUpdatePath,
      path: _ctx.path,
      class: "the-explorer"
    }, null, 8, ["onUpdate:path", "path"])
  ]))
}