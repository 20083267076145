
import { api } from "@/api";
import { Options, Vue } from "vue-class-component";

@Options({})
export default class SetPassword extends Vue {
    newPassword = "";
    repeat = "";
    async onConfirm() {
        const l = this.$loading({});
        try {
            await api.admin.setPassword(this.newPassword);
            this.$message("密码修改成功");
            this.newPassword = "";
            this.repeat = "";
        } finally {
            l.close();
        }
    }
}
