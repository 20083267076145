
import { api, TServerStatus } from "@/api";
import { Vue, Options } from "vue-class-component";

@Options({})
export default class Panel extends Vue {
  serverStatus: TServerStatus | null = null;

  async mounted() {
    try {
      if ((await api.admin.hello()) === "hello world!") {
        // do nothing
      }
    } catch (e) {
      console.log("Err");
      this.$message({
        showClose: false,
        message: "请重新登录",
        type: "error",
        duration: 0,
      });
      setTimeout(() => window.open("/admin/login", "_blank"), 3000);
    }

    this.serverStatus = await api.admin.serverStatus();
  }

  get textMem(): string {
    const s = this.serverStatus!;
    return `${s.MemUsage.toLocaleString()} MiB / ${s.MemTotal.toLocaleString()} MiB`;
  }

  get percentMem(): number {
    const s = this.serverStatus!;
    return (s.MemUsage / s.MemTotal) * 100;
  }

  get textDisk(): string {
    const s = this.serverStatus!;
    return `${s.DiskUsage.toLocaleString()} MiB / ${s.DiskTotal.toLocaleString()} MiB`;
  }

  get percentDisk(): number {
    const s = this.serverStatus!;
    return (s.DiskUsage / s.DiskTotal) * 100;
  }

  get textProcessor(): string {
    const s = this.serverStatus!;
    return `${s.ProcessorUsage15Min}%`;
  }

  get percentProcessor(): number {
    const s = this.serverStatus!;
    return s.ProcessorUsage15Min;
  }

  colorMap(percent: number): string {
    const hue = ((1 - percent / 100) * 120).toString(10);
    return ["hsl(", hue, ",70%,70%)"].join("");
  }

  async onLogout() {
    await api.admin.logout();
    this.$message({
      showClose: false,
      message: "注销成功",
      type: "info",
      duration: 0,
    });

    setTimeout(() => this.$router.push({ name: "AdminLogin" }), 3000);
  }
}
