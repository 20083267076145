
import { api, TFileEntry, TPost } from '@/api';
import { Options, Vue } from 'vue-class-component';
import { h } from 'vue';
import { CHANGE_EVENT } from 'element-plus';

type ExtendedFileEntry = TFileEntry & {
    FullPath: string;
}

@Options({
    props: {
        selectedItemPath: { type: String, required: false, default: null }, // as selected file or directory path, a directory path ends with '/'
        multiple: { type: Boolean, default: false },
        extension: { type: Array, required: false },
    }
})
export default class FileExplorer extends Vue {
    declare $refs: { theFileInput: HTMLInputElement };

    selectedItemPath!: string | null;
    multiple!: boolean;
    extension!: Array<string> | undefined;

    // valid paths are '', 'a/', 'a/b/'
    get breadcrumbs(): { name: string, path: string }[] {
        const ret = [{ name: '~', path: '' }];
        const splitted = (this.profile.path || '').split('/');

        let cur = '';
        splitted.slice(0, splitted.length - 1).forEach((v, idx) => {
            cur += v + '/';
            ret.push({ name: v, path: cur });
        });
        return ret;
    }

    async mounted() {
        this.readProfile();

        let targetPath = null === this.selectedItemPath ? this.profile.path : this.derivePath(this.selectedItemPath);
        try {
            await this.cd(targetPath);
        } catch (e) {
            this.$message.error("invalid path: " + targetPath);
            await this.cd('');
        }
        this.$watch('profile', () => {
            this.writeProfile();
        }, { deep: true });
        this.$watch('selectedItems', () => {
            if (this.selectedItems.size === 1) {
                this.$emit('update:selectedItemPath', this.first);
            } else {
                this.$emit('update:selectedItemPath', undefined);
            }
        }, { deep: true });
    }

    derivePath(filePath: string): string {
        if (filePath.endsWith('/')) {
            return filePath;
        }
        return filePath.split(/\/(?=[^\/]+$)/)[0];
    }

    // items
    private items: ExtendedFileEntry[] = [];
    selectedItems: Set<string> = new Set(); // fullPath
    get first(): string {
        return this.selectedItems.values().next().value;
    }

    toggleItemSelected(fullPath: string, selected: boolean) {
        if (!selected) {
            this.selectedItems.delete(fullPath);
        } else {
            if (!this.multiple) {
                this.selectedItems.clear();
            }
            this.selectedItems.add(fullPath);
        }
    }

    selectable(item: ExtendedFileEntry): boolean {
        if (this.extension === undefined) {
            return true;
        }

        if (item.IsDir) {
            return false;
        }

        return this.extension.findIndex(x => item.Name.toLowerCase().endsWith(x)) >= 0;
    }


    async onOpenItem(item: ExtendedFileEntry) {
        if (item.IsDir) {
            await this.cd(this.profile.path + item.Name + '/');
        } else {
            window.open(api.fileURL(item.FullPath), '_blank');
        }
    }

    get filtered(): ExtendedFileEntry[] {
        const ext = this.extension;
        if (ext === undefined) {
            return this.items;
        }

        return this.items.filter(item => {
            if (item.IsDir) {
                return true;
            }
            return ext.findIndex(x => item.Name.toLowerCase().endsWith(x)) >= 0;
        });
    }

    // UI toolbar operations
    toolbar = true;

    async cd(path: string) {
        this.profile.path = path;
        const l = this.$loading();
        try {
            await this.fetchData();
        } finally {
            l.close();
        }
    }

    async mkdir() {
        const l = this.$loading();
        try {
            const v = (await this.$messageBox.prompt('请输入欲创建文件夹的名称', '新建文件夹')).value;
            await api.admin.setDirectory(this.profile.path + v);
            await this.fetchData();
        } finally {
            l.close();
        }
    }

    async rename() {
        const l = this.$loading();
        try {
            const v = (await this.$messageBox.prompt('请输入新的名称', '重命名', { inputValue: this.first.slice(this.profile.path.length) })).value;
            await api.admin.renameItem(this.first, this.profile.path + v);
            await this.fetchData();
            this.selectedItems.clear();
            this.selectedItems.add(this.profile.path + v);
        } finally {
            l.close();
        }
    }

    onUploadClick() {
        this.$refs.theFileInput.click();
    }
    async onUpload(e: Event) {
        if (!(e.target instanceof HTMLInputElement)) {
            return;
        }

        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const l = this.$loading({});
            try {
                await api.admin.setFile(file, this.profile.path + file.name);
                e.target.value = "";
                await this.fetchData();
            } finally {
                l.close();
            }
        }
    }

    async move2here() {
        const items = Array.from(this.selectedItems);
        const message = h('div', null, [h('div', null, '确认移动这些文件至此？'), h('div', null, '同名文件将被覆盖'), h('br'), h('div', null, items.map(i => h('div', null, i)))]);
        await this.$confirm(message, "移动", { confirmButtonText: "移动", cancelButtonText: "放弃", type: 'info' });
        const l = this.$loading();
        try {
            await api.admin.moveItems(items, this.profile.path);
            this.selectedItems.clear();
        } catch (e: any) {
            const parsed: { Error: string, Moved: string[] } = JSON.parse(e);
            this.$notify({ title: '错误', message: parsed.Error, type: 'error' });
            parsed.Moved.forEach(x => this.selectedItems.delete(x));
        }
        finally {
            await this.fetchData();
            l.close();
        }
    }

    async deleteAll() {
        const items = Array.from(this.selectedItems);
        const message = h('div', null, [h('div', null, '确认删除这些文件？'), h('br'), h('div', null, items.map(i => h('div', null, i)))]);
        await this.$confirm(message, "删除", { confirmButtonText: "删除", cancelButtonText: "放弃", type: 'warning' });
        const l = this.$loading();
        try {
            await api.admin.delFiles(items);
            await this.fetchData();
        } finally {
            l.close();
        }
    }

    async refresh() {
        const l = this.$loading();
        try {
            await this.fetchData();
        } catch (e) {
            l.close();
        }
    }




    // status
    get status(): string {
        if (this.selectedItems.size > 1) {
            return `已选择 ${this.selectedItems.size} 个项目`;
        } else if (this.selectedItems.size === 1) {
            return `已选择：${this.first}`;
        } else {
            return 'File Explorer';
        }
    }

    //   fetchData
    async fetchData() {
        const items = await api.admin.listFiles(this.profile.path);
        this.items = items.map(x => ({ ...x, FullPath: this.profile.path + x.Name }));
    }

    // Profile
    profile = {
        view: 'list' as 'list' | 'icon' | 'detail',
        path: '' as string,
    };


    profileKey = 'FileExplorer.profile';
    readProfile() {
        const profile: typeof this.profile = JSON.parse(window.localStorage.getItem(this.profileKey) || '{}');
        profile.view = profile.view || 'detail';
        profile.path = profile.path || '';
        this.profile = profile;
    }
    writeProfile() {
        window.localStorage.setItem(this.profileKey, JSON.stringify(this.profile));
    }

    // helper: bytes
    bytesForHuman(bytes: number): string {
        if (undefined === bytes) {
            return '-';
        }

        const units = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB']

        let i = 0
        for (i; bytes > 1024; i++) {
            bytes /= 1024;
        }

        if (i >= units.length) {
            return bytes + 'B';
        }
        return bytes.toFixed(1) + ' ' + units[i]
    }

    // helper: image thumb
    isImage(filename: string): boolean {
        return filename.match(/.(jpg|jpeg|png|gif)$/i) !== null;
    }

    imageThumb = api.admin.imageThumb;
}
