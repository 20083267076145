import { h, render, watch, isVNode, AppContext } from 'vue';
import Quill, { QuillOptionsStatic } from 'quill';
import FileDialog from '@/components/FileDialog.vue';
import { api } from './api';

const Parchment = Quill.import('parchment');

const Align = Quill.import('attributors/style/align');
Quill.register(Align, true);

const Size = Quill.import('attributors/style/size');
Size.whitelist = ['0.75em', false, '1.5em', '2.5em'];

Quill.register(Size, true);

// let FontWeightStyle = new Parchment.Attributor.Style('font-weight', 'font-weight', {
//     scope: Parchment.Scope.INLINE,
//     whitelist: ['400', '500', '600', '700', '800'],
// });
// Quill.register(FontWeightStyle);

// let LetterSpacingStyle = new Parchment.Attributor.Style('letter-spacing', 'letter-spacing', {
//     scope: Parchment.Scope.INLINE,
//     whitelist: ['0px', '0.4px', '0.6px', '0.8px', '2.4px'],
// });
// Quill.register(LetterSpacingStyle);

// let LineHeightStyle = new Parchment.Attributor.Style('line-height', 'line-height', {
//     scope: Parchment.Scope.INLINE,
//     whitelist: ['0px', '8px', '16px', '24px', '32px'],
// });
// Quill.register(LineHeightStyle);

// let MarginBottomStyle = new Parchment.Attributor.Style('margin-bottom', 'margin-bottom', {
//     scope: Parchment.Scope.BLOCK,
//     whitelist: ['initial', '0px', '4px', '8px', '16px', '24px', '32px', '48px'],
// });
// Quill.register(MarginBottomStyle);

let WidthStyle = new Parchment.Attributor.Style('width', 'width', {
    scope: Parchment.Scope.INLINE,
    whitelist: ['initial', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'],
});
Quill.register(WidthStyle);

let appContext: AppContext | null = null;
export const setAppContent = (app: AppContext) => appContext = app;

const fileDialog = (extension: Array<string> | null = null) =>
    new Promise<string>((resolve, reject) => {
        const container = document.createElement("div");

        const vnode = h(FileDialog, {
            extension: extension,
            onClose: (path: string) => {
                render(null, container);
                container.remove();

                if (path) {
                    resolve(path);
                } else {
                    reject('cancel');
                }
            }
        });

        vnode.appContext = appContext;
        render(vnode, container);
        document.body.appendChild(container.firstElementChild!);
        return vnode.component;

    });


const options: QuillOptionsStatic = {
    theme: 'snow',
    placeholder: '请输入内容',
    modules: {
        toolbar: {
            container: [
                // 字体
                [{ 'size': Size.whitelist }],  // custom dropdown
                [{ 'font': [] }],
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                ['link'],


                // 组织关系
                [{ 'align': [] }],
                // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],


                // 附加物
                ['blockquote', 'code-block', 'image', { 'width': WidthStyle.whitelist }],

                ['clean']                                         // remove formatting button
            ],
            handlers: {
                image: async function imageHandler() {
                    // this is toolbar instance
                    let range = (this as any as { quill: Quill }).quill.getSelection()!;
                    const path = api.fileURL(await fileDialog(['.jpg', '.jpeg', '.png', '.gif']));
                    (this as any).quill.insertEmbed(range.index, 'image', path, Quill.sources.USER);
                    // (this as any).quill.formatText(range.index, 1, 'width', '100%');
                }
            },
        }
    }
};

export default options;



