import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64008f6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "entity" }
const _hoisted_2 = { class: "about" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      (_ctx.about !== null)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "content",
            innerHTML: _ctx.about
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true)
    ])), [
      [_directive_loading, _ctx.about === null]
    ])
  ]))
}