
import { ElDialog } from 'element-plus';
import { Options, Vue } from 'vue-class-component';
import FileExplorer from './FileExplorer.vue';


@Options({
    components: {
        'file-explorer': FileExplorer,
    },
    props: {
        initialPath: { type: String, required: false }, // as selected file or directory path, a directory path ends with '/'
        extension: { type: Array, required: false },
    },
    emits: ['close']
})
export default class FileDialog extends Vue {
    initialPath!: string | undefined;
    extension!: Array<string> | undefined;

    prompted = true;

    selectedItemPath: string | null = null;

    path2emit?: string;

    beforeMount() {
        if (this.initialPath !== undefined) {
            this.selectedItemPath = this.initialPath;
        }
    }


    onCommit() {
        this.prompted = false;
        this.path2emit = this.selectedItemPath!;
    }
    onClosed() {
        this.$emit('close', this.path2emit);
    }
}
