
import { api, TPost } from '@/api';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class Posts extends Vue {
    rawPosts: Omit<TPost, 'Content'>[] = [];
    loading = true;

    get posts(): { Date: string, Title: string, Path: string }[] {
        const copied = this.rawPosts;
        copied.sort((a, b) => b.Date - a.Date);

        return copied.map(p => ({
            ID: p.ID,
            Date: (new Date(p.Date * 1000)).toLocaleDateString(),
            Title: p.Title,
            Path: p.Path,
        }));
    }

    async mounted() {
        this.rawPosts = await api.public.getPosts();
        this.loading = false;
    }
}
