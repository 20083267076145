
import { api } from "@/api";
import { Options, Vue } from "vue-class-component";
import FileExplorer from '@/components/FileExplorer.vue';

@Options({
    components: {
        'file-explorer': FileExplorer
    }
})
export default class Files extends Vue {


}
