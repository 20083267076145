
import { api, TSettingKey } from "@/api";
import { Options, Vue } from "vue-class-component";

@Options({})
export default class Footer extends Vue {
  links: { Title: string, URL: string }[] = [];
  async beforeMount() {
    this.links = await api.public.getLinks();
  }
}
