
import { Options, Vue } from 'vue-class-component';
// import FileExplorer from '@/components/FileExplorer.vue';
import SharingExplorer from '@/components/SharingExplorer';
// import { defineComponent } from 'vue';



@Options({
    components: {
        'sharing-explorer': SharingExplorer,
    }
})
export default class Sharing extends Vue {
    path = '';
    beforeCreate() {
        let path = this.$route.params['path'] as string;
        if (path !== "" && !path.endsWith('/')) {
            path += "/";
        }
        this.path = path;
    }

    onUpdatePath(path: string) {
        window.history.replaceState(history.state, '', '/sharing/' + path)
    }
}
