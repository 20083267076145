
import { api } from "@/api";
import { Vue, Options } from "vue-class-component";

@Options({})
export default class Login extends Vue {
  form = {
    password: "",
  };

  get valid(): boolean {
    return this.form.password !== "";
  }

  async mounted() {
    try {
      if ((await api.admin.hello()) === "hello world!") {
        console.log("已经登录");
        this.$router.push({ name: "AdminWelcome" });
      }
    } catch (e) { }
  }

  async onSubmit() {
    const l = this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    try {
      await api.public.adminLogin(this.form.password);
      this.$router.push({ name: "AdminWelcome" });
    } catch (e) {
      this.$message.error("登录失败");
    } finally {
      l.close();
    }
  }
}
