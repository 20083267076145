
import { api, TSettingKey } from "@/api";
import { Options, Vue } from "vue-class-component";

@Options({})
export default class About extends Vue {
    content: string | null = null;

    async mounted() {
        this.content = (await api.admin.getSetting(TSettingKey.SkAbout)).Value;
    }

    async onSave() {
        const loading = this.$loading();
        await api.admin.setSetting(TSettingKey.SkAbout, this.content!);
        loading.close();
        this.$message("保存成功");
    }
}
